
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-background/80 backdrop-blur-md border-t border-white/10 mt-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <Link to="/" className="text-xl font-bold text-white tracking-tight flex items-center">
              <span className="bg-primary px-2 py-1 rounded mr-1">CAM</span>
              BIRDS
            </Link>
            <p className="mt-4 text-muted-foreground text-sm">
              Premium adult content directory connecting you with verified models worldwide.
            </p>
          </div>
          
          <div>
            <h3 className="text-white font-medium mb-4">Escort Services</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/escorts/korean-escorts-london" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Korean Escorts London
                </Link>
              </li>
              <li>
                <Link to="/escorts/chinese-escorts-london" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Chinese Escorts London
                </Link>
              </li>
              <li>
                <Link to="/escorts/milton-keynes" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Milton Keynes Escorts
                </Link>
              </li>
              <li>
                <Link to="/escorts/luton" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Luton Escorts
                </Link>
              </li>
              <li>
                <Link to="/escorts/stoke-on-trent" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Stoke-on-Trent Escorts
                </Link>
              </li>
              <li>
                <Link to="/escorts/newcastle-upon-tyne" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Newcastle Escorts
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white font-medium mb-4">Dominatrix</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/dominatrix/northwest" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Northwest Dominatrix
                </Link>
              </li>
              <li>
                <Link to="/dominatrix/wales" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Wales Dominatrix
                </Link>
              </li>
              <li>
                <Link to="/dominatrix/kent" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Kent Dominatrix
                </Link>
              </li>
              <li>
                <Link to="/mistress/chester" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Mistress Chester
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white font-medium mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy-policy" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-muted-foreground hover:text-white transition-colors text-sm">
                  Blog
                </Link>
              </li>
            </ul>
            <p className="text-muted-foreground text-sm mt-4">
              This site contains adult content and is intended for adults aged 18 or older.
            </p>
            <p className="text-muted-foreground text-sm mt-2">
              All models featured on this site are 18 years or older.
            </p>
          </div>
        </div>
        
        <div className="border-t border-white/10 mt-8 pt-6 flex flex-col md:flex-row justify-between items-center">
          <p className="text-muted-foreground text-sm">
            © {currentYear} CAMBIRDS. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
