
import React, { useState } from 'react';
import { Upload } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

interface ImageUploaderProps {
  currentImage: string;
  onImageChange: (imageUrl: string) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ currentImage, onImageChange }) => {
  const [imageUrl, setImageUrl] = useState(currentImage);
  const [uploadError, setUploadError] = useState<string | null>(null);
  
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.target.value);
  };
  
  const handleUrlSubmit = () => {
    // Simple validation
    if (!imageUrl.trim()) {
      setUploadError('Please enter a valid URL');
      return;
    }
    
    // Check if URL is valid
    try {
      new URL(imageUrl);
      onImageChange(imageUrl);
      setUploadError(null);
    } catch (error) {
      setUploadError('Please enter a valid URL');
    }
  };
  
  // In a real app, this would handle file uploads to a storage service
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    // For demo purposes, we'll just use a FileReader to get a data URL
    // In production, you would upload to a storage service
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setImageUrl(reader.result);
        onImageChange(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Card>
      <CardContent className="p-4">
        <div className="mb-4">
          <img
            src={currentImage || 'https://via.placeholder.com/800x400?text=No+Image'}
            alt="Preview"
            className="w-full h-48 object-cover rounded-md"
          />
        </div>
        
        <Tabs defaultValue="url">
          <TabsList className="grid grid-cols-2 mb-4">
            <TabsTrigger value="url">Image URL</TabsTrigger>
            <TabsTrigger value="upload">Upload</TabsTrigger>
          </TabsList>
          
          <TabsContent value="url" className="space-y-4">
            <div className="flex items-center space-x-2">
              <Input
                type="text"
                value={imageUrl}
                onChange={handleUrlChange}
                placeholder="Enter image URL"
                className="flex-1"
              />
              <Button onClick={handleUrlSubmit}>Set</Button>
            </div>
            {uploadError && <p className="text-destructive text-sm">{uploadError}</p>}
          </TabsContent>
          
          <TabsContent value="upload">
            <div className="space-y-4">
              <Label htmlFor="file-upload" className="cursor-pointer">
                <div className="border-2 border-dashed border-accent/30 rounded-md p-6 text-center hover:border-accent/60 transition-colors">
                  <Upload className="mx-auto h-12 w-12 text-muted-foreground" />
                  <p className="mt-2 text-sm text-muted-foreground">
                    Click to upload or drag and drop
                  </p>
                  <p className="text-xs text-muted-foreground">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
                <Input
                  id="file-upload"
                  type="file"
                  className="sr-only"
                  accept="image/*"
                  onChange={handleFileUpload}
                />
              </Label>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default ImageUploader;
