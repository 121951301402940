
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LayoutDashboard, FileText, LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  
  const handleLogout = () => {
    localStorage.removeItem('adminAuthenticated');
    toast({
      title: "Logged out",
      description: "You have been logged out successfully"
    });
    navigate('/admin/login');
  };

  return (
    <div className="min-h-screen bg-background flex">
      {/* Sidebar */}
      <div className="w-64 bg-card border-r border-accent/20 p-4">
        <div className="mb-6">
          <h1 className="text-xl font-bold">CAMBIRDS Admin</h1>
        </div>
        <nav className="space-y-2">
          <Link to="/admin/dashboard" className="flex items-center p-2 rounded-md hover:bg-accent/10">
            <LayoutDashboard size={18} className="mr-2" />
            Dashboard
          </Link>
          <Link to="/admin/blog-posts" className="flex items-center p-2 rounded-md hover:bg-accent/10">
            <FileText size={18} className="mr-2" />
            Blog Posts
          </Link>
        </nav>
        <div className="absolute bottom-4 left-4 right-4">
          <Button variant="outline" className="w-full flex items-center justify-center" onClick={handleLogout}>
            <LogOut size={18} className="mr-2" />
            Logout
          </Button>
        </div>
      </div>
      
      {/* Main content */}
      <div className="flex-1 p-6 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default AdminLayout;
