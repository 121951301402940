
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from '@/hooks/use-toast';
import { BlogPost, getBlogPostById, createBlogPost, updateBlogPost } from '@/services/blogService';
import ImageUploader from '@/components/admin/ImageUploader';

const BlogPostEditor = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isNewPost = id === 'new';
  
  const [formData, setFormData] = useState<Partial<BlogPost>>({
    title: '',
    excerpt: '',
    category: 'Escorts',
    content: '',
    image: 'https://images.unsplash.com/photo-1649972904349-6e44c42644a7?q=80&w=1000',
  });
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isNewPost && id) {
      const post = getBlogPostById(id);
      if (post) {
        setFormData(post);
      } else {
        toast({
          title: "Error",
          description: "Blog post not found",
          variant: "destructive"
        });
        navigate('/admin/blog-posts');
      }
    }
  }, [id, isNewPost, navigate, toast]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (imageUrl: string) => {
    setFormData(prev => ({ ...prev, image: imageUrl }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (isNewPost) {
        await createBlogPost(formData as Omit<BlogPost, 'id' | 'date' | 'slug'>);
        toast({
          title: "Success",
          description: "Blog post created successfully"
        });
      } else if (id) {
        await updateBlogPost(id, formData);
        toast({
          title: "Success",
          description: "Blog post updated successfully"
        });
      }
      navigate('/admin/blog-posts');
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "An error occurred",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AdminLayout>
      <div className="mb-6">
        <h1 className="text-3xl font-bold">
          {isNewPost ? "Create New Blog Post" : "Edit Blog Post"}
        </h1>
      </div>

      <form onSubmit={handleSubmit} className="glass-card rounded-lg border border-accent/20 p-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <Label htmlFor="title">Title</Label>
            <Input
              id="title"
              name="title"
              value={formData.title || ''}
              onChange={handleChange}
              placeholder="Blog post title"
              required
            />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="category">Category</Label>
            <Select
              value={formData.category}
              onValueChange={value => handleSelectChange('category', value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Escorts">Escorts</SelectItem>
                <SelectItem value="Dominatrix">Dominatrix</SelectItem>
                <SelectItem value="Massage">Massage</SelectItem>
                <SelectItem value="Locations">Locations</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        
        <div className="space-y-2">
          <Label htmlFor="excerpt">Excerpt</Label>
          <Textarea
            id="excerpt"
            name="excerpt"
            value={formData.excerpt || ''}
            onChange={handleChange}
            placeholder="Short description of the blog post"
            required
            rows={3}
          />
        </div>
        
        <div className="space-y-2">
          <Label>Featured Image</Label>
          <ImageUploader
            currentImage={formData.image || ''}
            onImageChange={handleImageChange}
          />
        </div>
        
        <div className="space-y-2">
          <Label htmlFor="content">Content (HTML)</Label>
          <Textarea
            id="content"
            name="content"
            value={formData.content || ''}
            onChange={handleChange}
            placeholder="Blog post content in HTML format"
            required
            rows={12}
          />
        </div>
        
        <div className="flex justify-end space-x-2">
          <Button 
            type="button" 
            variant="outline" 
            onClick={() => navigate('/admin/blog-posts')}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : isNewPost ? "Create Post" : "Update Post"}
          </Button>
        </div>
      </form>
    </AdminLayout>
  );
};

export default BlogPostEditor;
